@import "../../../assets/scss/abstracts/variabale";


/* ============= Title CSS ============= */
.mainTitle {
    // margin-bottom: 20px;
    font-size: 20px;
    color: $dark;
    font-family: $font-prime;

    & .titleIcon {
        border-radius: 5px;
        box-shadow: 0 3px 8.3px 0.7px rgba($second, .35);
        background: linear-gradient(90deg, $prime, $second);
        color: $light;
        transition: .3s;

        &:hover {
            background: linear-gradient(90deg, $second, $prime);
        }
    }


    @media screen and (max-height:600px) {
        font-size: 16px;
    }
}