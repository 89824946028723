@import "abstracts/variabale";

body {
    // background-color: #2e2e2e;
    font-size: 18px;
    font-family: $font-prime;
}

:invalid {
    outline: none;
    color: $second;
}

.font-prime {
    font-family: $font-prime;
}

.font-second {
    font-family: $font-second;
}

a {
    text-decoration: none;
}

li {
    list-style: none;
}

img {
    width: 100%;
    object-fit: cover;
}

section {
    padding: 20px 0;
}

.rotate90 {
    transform: rotate(90deg);
}

.rotate180 {
    transform: rotate(180deg);
}

.cursor-pointer {
    cursor: pointer;
}

button {
    border: none;
}

.midBox {
    @include midels(flex, center, center);
}

.betBox {
    @include midels(flex, space-between, center);
}

@for $i from 1 through 6 {

    .h#{$i},
    h#{$i} {
        font-weight: bold;
        font-family: $font-second;
    }
}

/* width */
::-webkit-scrollbar {
    width: 3px;
    height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $second;
    border-radius: 10px;
}

/* Admin Penal Setup */
.mainAdminGrid {
    transition: 0.4s;
    display: grid;
    grid-template-areas:
        "navBar navBar"
        "sideBar admin";
    grid-template-columns: 300px auto;
    width: 100%;
    grid-template-rows: 116px calc(100vh - 116px);
    overflow: hidden;

    &.webAdminGrid {
        grid-template-columns: 140px auto;
    }

    // @include maxMediaQuery($lg) {
    //     grid-template-columns: 0px auto;

    //     &.webAdminGrid {
    //         grid-template-columns: 70px auto;
    //     }
    // }

    @include maxMediaQuery($md) {
        grid-template-areas:
            "sideBar navBar"
            "sideBar admin";

        grid-template-columns: 70px auto;

        &.webAdminGrid {
            grid-template-columns: 70px auto;
        }
    }

    @include maxMediaQuery($sm) {
        grid-template-columns: 0 auto;

        &.webAdminGrid {
            grid-template-columns: 0 auto;
        }
    }

    & .mainSidebar {
        grid-area: sideBar;
    }

    & .mainNavbar {
        grid-area: navBar;
    }

    & .mainAdmin {
        grid-area: admin;
    }
}




/* ============= Login CSS ============= */

.mainLoginPage {
    height: 100vh;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;


    & .loginDiv {
        & .loginPage {
            border: 1px solid $body;
            padding: 40px 30px;
            backdrop-filter: blur(10px);
            // background-color: rgba($blue,.2);
            border-radius: 20px;

            & .loginInput {
                & label {
                    color: $light;
                }

                & input {
                    background-color: $body;
                }
            }
        }
    }
}








// ===== Print size page =====

.receipt-container {
    max-width: 21cm;
    margin: auto;
    border: 1px solid #ccc;
}


.receipt-main {
    margin: auto;
    max-width: 21cm;
    background-color: #fff;
    max-height: 50%;
    min-height: 50%;
    // border: 4px solid #000;
    padding: 30px 30px;
}

.receipt-header {
    margin-bottom: 20px;
}

@media screen {
    .receipt-container {
        width: 100%;
    }
}

.startReceipt {
    border: solid 2px;
    max-height: 100%;
    padding: 10px;
}

.receipt-header {
    & .headerContet {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & .iconContent {
            & .iconsDetails {
                & .icons {
                    display: flex;
                    align-items: center;
                    margin-bottom: 5px;

                    & .iconsImg {
                        border: solid 2px;
                        border-radius: 50%;
                        margin-right: 5px;
                        font-size: 14px;
                    }

                    & .iconsText {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

.receipt-body {
    & .startBody {
        & .row {
            &>div {
                margin-bottom: 15px;

                & .tages {
                    text-transform: uppercase;
                    font-size: 16px;
                    font-weight: 600;
                }
            }
        }
    }
}

.receipt-footer {
    & .startFooter {
        & .row {
            &>div {
                margin-bottom: 20px;

                & .tages {
                    font-size: 16px;
                    font-weight: 600;

                }

                & .signLine {
                    width: 200px;
                    border-bottom: 2px solid;
                    background-color: $dark;
                    display: block;
                    margin-top: 50px;
                }
            }
        }
    }
}


// ===== Print Salary page =====
.staffInformation {
    text-transform: uppercase;
    font-family: $font-second;

    & .staffInformationDetails {
        & .staffDetailsText {
            margin-bottom: 5px;
            letter-spacing: .5px;
            font-size: 14px;
            font-weight: 600;
        }
    }
}



.secondPrintMain {
    padding: 0;
}

.secondPrint {
    display: none;
}

.doublePrint {
    color: unset;

    & .secondPrintMain {
        padding-top: 15px !important;
        padding: 30px 30px;
    }

    & .secondPrint {
        display: block !important;
    }
}

.staffInfoBody {
    & table {
        & tr {
            border: solid 1px $dark;
            box-shadow: unset;

            th {
                color: $dark;
                text-align: start;
            }

            td {
                color: $darkGray;
                text-align: end;
            }

            td,
            th {
                border: solid 1px $dark;
                padding: 2px 4px;
                font-size: 12px;
                line-height: 14px;
            }
        }

    }
}

// ====== Fee PDF =====
.fee-container {
    max-width: 100%;
    margin: auto;
    border: 1px solid #ccc;
}

.startFeesTable {
    th {
        color: $dark;
    }

    tr {
        border: solid 1px $dark !important;
    }

    td,
    th {
        border: solid 1px $dark !important;
    }
}

.mainAdminProfile {


    & .adminBgDetails {
        position: relative;
        border: 1px solid $second;
        font-family: $font-prime;
        border-radius: 20px;
        background-color: lighten($second-light, 15);
        overflow: hidden;

        .mainProfileDetails {
            position: relative;
            z-index: 1;
        }
    }


    & .profileNameImage {
        & .profileMainImage {
            margin-top: -70px;

            @include maxMediaQuery($md) {
                margin-top: -40px;
            }

            & .adminProfileImage {
                & .entryImage {
                    cursor: pointer;
                    position: relative;

                    & i {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }

                    & .adminImage {
                        opacity: 0;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                    }

                    border-radius: 50%;
                    position: absolute;
                    bottom: 0;
                    right: 15px;
                }

                & .adminImageHeader {
                    border-radius: 50%;
                }

                & h3 {
                    font-family: $font-prime;
                }
            }
        }
    }

    & .adminDetails {
        border: 1px solid $second;
        border-radius: 20px;
        margin-bottom: 20px;
        white-space: nowrap;

        & .adminProfileTitle {
            font-family: $font-second;
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        & .adminDetailsBox {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 5px;

            & .adminDetailTitle {
                font-family: $font-second;
                font-size: 14px;

                @include maxMediaQuery($md) {
                    font-size: 12px;
                }
            }

            & .inputData {

                & input,
                & textarea {
                    margin: 0;
                    font-size: 14px;
                    text-align: end;
                    height: auto;
                    width: 100%;
                    padding: 0 5px;
                    font-weight: 600;
                    border-color: transparent;
                    white-space: pre-wrap;

                    @include maxMediaQuery($md) {
                        font-size: 12px;
                    }

                    &[type="date"]::-webkit-calendar-picker-indicator {
                        display: none;
                    }

                    &[type="time"]::-webkit-calendar-picker-indicator {
                        display: none;
                    }
                }

                & textarea {
                    min-height: 50px;
                    resize: vertical;
                    overflow: auto;
                    word-wrap: break-word;
                }

                & .errorMessage {
                    display: none;
                }
            }
        }
    }
}


.courseCategory {

    .courseIcon {
        box-shadow: 0 0px 26px lighten($second, 50);
    }


    &:hover {
        box-shadow: 0 0px 26px lighten($second, 50);
        border-color: $transparent !important;

        .courseIcon {
            box-shadow: 0 0px 0px lighten($second, 50);
        }
    }
}

.portfolioImage {
    img {
        transition: 3s;
    }

    &:hover {
        img {
            transition: 7s;
            transform: translateY(calc(-100% + 500px));
        }
    }
}