@import "../../../assets/scss/abstracts/variabale";

/* ============= Dialog CSS ============= */
.dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #0000006b;
    z-index: 6;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);

    @include maxMediaQuery($lg) {
        & .adjustHeight {
            max-height: 350px;
            overflow: auto;
        }
    }

    & .masterDiaogBox {
        border-radius: 20px;
        overflow: hidden;

        & .closeButton {
            font-size: 26px;
            // background-color: $second-light;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 26px;
            width: 26px;
            border-radius: 50%;
            color: $second;
            margin-left: auto;
            cursor: pointer;

            & .ri-close-circle-line::before {
                height: 100%;
                width: 100%;
                background-color: $body;
                border-radius: 50%;
            }
        }

        & .mainDiaogBox {
            background-color: $body;
            max-height: 98vh;
            overflow-x: hidden;
            overflow-y: auto;
            position: relative;

            & .formHead {
                @media screen and (min-height:350px) {
                    position: sticky;
                }

                top: 0;
                left: 0;
                padding: 20px;
                padding-bottom: 10px;
                width: 100%;
                background-color: $body;
                z-index: 1;

                & .headTitle text-second text-uppercase {
                    margin: 0;
                }
            }

            & .formBody {
                padding: 10px 20px;
            }

            & .formFooter {
                @media screen and (min-height:350px) {
                    position: sticky;
                }

                bottom: 0;
                left: 0;
                padding: 20px;
                padding-bottom: 15px;
                width: 100%;
                background-color: $body;
                z-index: 1;
            }

            & .closeButton {
                font-size: 26px;
                // background-color: $second-light;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 26px;
                width: 26px;
                border-radius: 50%;
                color: $second;
                margin-left: auto;
                cursor: pointer;

            }

            @include maxMediaQuery($md) {
                & h2 {
                    font-size: 20px;
                }
            }
        }
    }
}

/* ============= Page Dialogue CSS ============= */

.dialoguePage {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 6;
    height: 100%;
    width: 100%;
    background-color: $body;
    padding: 20px 25px 0;

    @include maxMediaQuery($md) {
        padding: 20px 10px 0;
    }
}