@import "../../../assets/scss/abstracts/variabale";

/* ============= Pagination CSS ============= */

.pagination {
    padding: 20px;
    color: #949494;
    cursor: pointer;

    @include maxMediaQuery($md) {
        padding: 10px;
        font-size: 14px;
    }

    & .tableRang {
        justify-content: start;

        & .inputData {
            display: flex;
            width: 120px;
            align-items: center;

            @include maxMediaQuery($md) {
                font-size: 12px;
            }

            & .selectMain {
                margin-left: 10px;
                display: block;
            }
        }
    }

    & .count {
        color: $dark;
        margin-left: 15px;
        white-space: nowrap;

        @include maxMediaQuery($md) {
            margin-left: 10px;
            font-size: 12px;
        }
    }

    & .tableAccess {
        & .mainPaginatinBtn {
            & .paginationBtn {
                height: 35px;
                width: 35px;
                background-color: $second;
                color: $light;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                margin: 0 5px;

                @include maxMediaQuery($md) {
                    margin: 0 3px;
                    height: 25px;
                    width: 25px;
                    font-size: 12px;
                }

                &.pageBtnDisable {
                    background-color: lighten($gray, 20);
                    color: $darkGray;
                    border: 0;
                }
            }

            & .fixedBtn {
                background-color: transparent;
                color: $second;
                border: 1px solid $second;
            }
        }
    }

    & .paginationNumber {
        // border-radius: 0 !important;
        background-color: lighten($second-light, 10) !important;
        color: $second !important;
        border: 1px solid $second;

        &.active {
            background-color: $second !important;
            color: $light !important;
        }
    }
}