@import "../../assets/scss/abstracts/variabale";

/* ============= Sidebar CSS ============= */

@mixin fullViewNav() {


    border-radius: 15px;
    overflow: auto;
    height: 100%;
    width: 100%;
    // background-color: $body;
    background-color: $light;
    // box-shadow: 0 0 60px #0404041a;
    // overflow-x: auto;

    &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }


    & .sideBarLogo {
        padding: 15px 10px 10px;

        @include maxMediaQuery($md) {
            padding: 12px;
        }

        & .smallLogo {
            display: none;
            width: 55px;
            margin: auto;
        }

        & .logo {
            display: block;
            width: 155px;
            margin: auto;
        }

        & .closeIcon {
            display: none;
        }

        @include maxMediaQuery($md) {
            display: flex;
            justify-content: space-between;

            & .closeIcon {
                display: block;
                font-size: 18px;
            }

            & .logo {
                margin: unset;
            }

        }
    }

    & .navigation {

        & .categoryWiseSideBar {
            & .mainSideBar {
                & .sideBarHead {
                    padding: 10px;
                    padding-bottom: 5px;
                    font-size: 14px;
                    cursor: pointer;
                    transition: .3s;
                    font-weight: 500;
                    background-color: #ffffff79;
                    overflow: auto;

                    &:hover {
                        color: $second;
                    }
                }

                &.activeMenu {
                    & .sideBarHead {
                        & i {
                            display: none;
                        }
                    }
                }

                & .mainMenu {
                    &>li {

                        background-color: $body;

                        &.deactiveClick.activeMasterMenu {
                            &>a {
                                background-color: $body;
                                color: $second;
                            }
                        }

                        &.beforeActiveMaster {
                            & a {
                                border-bottom-right-radius: 15px;
                                border-bottom-left-radius: 15px;
                            }
                        }

                        &.afterActiveMaster {
                            & a {
                                border-top-right-radius: 15px;
                                border-top-left-radius: 15px;
                            }
                        }

                        &>.activeMenu {
                            color: $second !important;
                            background-color: $body;
                            position: relative;

                            &::before {
                                content: "";
                                position: absolute;
                                background: $second;
                                top: 50%;
                                transform: translateY(-50%);
                                left: -20px;
                                width: 30px;
                                height: 36px;
                                border-radius: 0 100% 100% 0 / 50%;
                            }
                        }

                        & .activeMenu {
                            // color: $light !important;
                            color: $second !important;
                        }

                        &>a {
                            background-color: $light;

                            & div {
                                & span {
                                    display: inline;
                                }
                            }

                            &>i {
                                display: block;
                                transition: .3s
                            }

                            // &:hover {
                            //   color: $light !important;
                            //   background-color: lighten($second, 30);
                            // }   
                        }
                    }

                    & li {
                        // margin: 5px 0;


                        & a {
                            color: lighten($dark, 55);
                            padding: 15px 25px;
                            transition: .4s;
                            font-size: 14px;
                            white-space: nowrap !important;


                            @include maxMediaQuery($md) {
                                font-size: 14px;
                            }

                            font-weight: 400;


                            &:hover {
                                color: $second;
                            }

                            & i {
                                font-size: 18px;
                                margin-right: 15px;
                            }

                            & img {
                                margin-right: 15px;
                            }

                        }

                        & .subMenu {
                            padding-left: 28px;
                            padding-top: 10px;

                            & .subhead {
                                text-align: start;
                                display: none;
                                margin-bottom: 20px;
                                cursor: pointer;
                                font-size: 16px;
                            }

                            & li {
                                & a {
                                    position: relative;

                                    &.activeMenu {
                                        color: $second !important;
                                    }

                                    &::before {
                                        content: "";
                                        width: 6px;
                                        height: 2px;
                                        background-color: $darkGray;
                                        position: absolute;
                                        left: 2px;
                                        top: 50%;
                                        transition: .4s;
                                        opacity: 1;
                                    }
                                }
                            }

                        }
                    }
                }

            }
        }
    }


}


@mixin smallViewNav() {

    height: 100%;

    @include maxMediaQuery($lg) {
        overflow: auto;
    }

    & .sideBarLogo {

        @include maxMediaQuery($md) {
            padding: 12px 0;
        }

        & .logo {
            display: none;
        }

        & .smallLogo {
            display: block;
            width: 40px;

            @include maxMediaQuery($lg) {
                width: 45px;
            }

            @include maxMediaQuery($md) {
                width: 40px;
            }
        }

        @include maxMediaQuery($lg) {
            & .closeIcon {
                display: none;
            }

            & .blackBox {
                left: -100%;
            }
        }
    }


    & .navigation {
        & .categoryWiseSideBar {
            & .mainSideBar {

                & .mainMenu {
                    &>li {
                        position: relative;

                        &>a {
                            justify-content: center;

                            &>i {
                                display: none !important;
                            }

                            & div {
                                & span {
                                    display: none !important;
                                }

                                & i {
                                    font-size: 20px !important;
                                    margin-right: 0;

                                    @include maxMediaQuery($md) {
                                        font-size: 16px;
                                    }
                                }
                            }

                            &>i {
                                font-size: 24px;
                                display: none;
                            }
                        }

                        &:hover .subMenu {
                            display: block !important;
                        }

                        & .subMenu {
                            position: absolute;
                            top: 0;
                            left: 100%;
                            padding-left: 0;
                            padding: 18px 10px 10px;
                            background-color: $light;
                            white-space: nowrap;
                            min-width: 200px;
                            box-shadow: 0 2px 4px #0f223a1f;
                            z-index: 1;

                            @include maxMediaQuery($md) {
                                min-width: 160px;
                            }

                            & .subhead {
                                font-weight: 600;
                                display: block;

                                @include maxMediaQuery($md) {
                                    margin-bottom: 10px;
                                    font-size: 14px;
                                }
                            }

                            & li {
                                & a {
                                    position: relative;
                                    padding-left: 20px;

                                    @include maxMediaQuery($md) {
                                        padding: 5px 15px;
                                        font-size: 12px;
                                    }

                                    &::before {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }

            }
        }
    }



}

.mainAdminGrid {

    position: relative;
    z-index: 6;

    .mainSidebar {

        transition: .3s;

        @include maxMediaQuery($md) {
            position: absolute;
            top: 0;
            left: 0;
            height: 100vh;
            width: 240px;
            box-shadow: unset;
            z-index: 6;
            padding: 0;
        }


        .startSideBar {
            color: $darkGray;
            font-size: 15px;
            transition: .4s;
            position: relative;
            z-index: 6;
            padding: 0 30px 20px;
            height: 100%;
            overflow: hidden;

            @include maxMediaQuery($md) {
                padding: 0;

                .sideBar {
                    border-radius: 0 !important;

                    & .blackBox {
                        height: 100vh;
                        width: 100%;
                        background-color: #000000b0;
                        position: fixed;
                        top: 0;
                        left: 0;
                        z-index: -1;
                        transition: .2s;
                        backdrop-filter: blur(2px)
                    }
                }
            }

            & .sideBar {

                @include fullViewNav();
            }
        }
    }



    /*Small Size*/
    &.webAdminGrid {
        .mainSidebar {

            height: 100%;

            @include maxMediaQuery($md) {
                width: 100%;
            }

            @include maxMediaQuery($sm) {
                width: 0;
            }

            .startSideBar {

                & .sideBar {
                    & .blackBox {
                        width: 0%;
                    }

                    @include smallViewNav();
                }
            }
        }

    }

}