@import "../../../assets/scss/abstracts/variabale";

/* ============= Swal Warning CSS ============= */

.swal2-container {
    background: rgba(0, 0, 0, 0.075) !important;

    & .swal2-popup {
        background: rgba(255, 255, 255, 0.4);
        box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);

        & .swal2-icon {
            border: none !important;

            & .swal2-icon-content {
                font-size: 5.75rem;
                color: $second;
            }
        }
    }
}