@import "../../../assets/scss/abstracts/variabale";

:root {
  --primeRange: #{$second};
}

.inputData {
  font-family: $font-prime;
  font-size: 20px;
  position: relative;
  text-align: start;

  /*Tablate Device*/
  @include maxMediaQuery($lg) {
    font-size: 16px;
  }

  // & .inputError {
  //   border: 1px solid $danger !important;
  // }

  // Error Message CSS
  & .errorMessage {
    margin-top: 5px;
    font-size: 14px;
    color: $prime;

    @include maxMediaQuery($md) {
      font-size: 14px;
    }

    @include maxMediaQuery($sm) {
      font-size: 12px;
    }
  }

  & input:invalid~.errorMessage {
    color: $prime;
    display: block;
  }

  // Input Labels CSS
  & label {
    user-select: none;
    font-weight: 500;
    cursor: pointer;
    font-size: 14px;
    color: darken($gray, 20%);

    // Tablate Device
    @include maxMediaQuery($sm) {
      font-size: 12px;
    }
  }

  & .srhIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    color: lighten($gray, 10);
  }

  & input {
    width: 100%;
    height: 40px;
    background: $light;
    border: none;
    padding: 0 20px;
    border-radius: 5px;
    font-size: 16px;

    &:focus {
      outline-color: $second;
      outline-width: 1px;
    }

    &::placeholder {
      color: $gray;
      font-size: 14px;
      font-family: $font-prime;

      @include maxMediaQuery($lg) {
        font-size: 14px;
      }
    }

    // mobile Device
    @include maxMediaQuery($sm) {
      padding: 0 15px;
      height: 38px;
      margin-top: 2px;
      font-size: 12px;

      &::placeholder {
        font-size: 12px;
      }
    }

    /* For Input Type Search */
    &[type="search"]::-webkit-search-results-decoration,
    &[type="search"]::-webkit-search-decoration,
    &[type="search"]::-webkit-search-cancel-button,
    &[type="search"]::-webkit-search-results-button {
      font-size: 16px;
    }

    &[type="search"] {
      @include maxMediaQuery($sm) {
        height: 30px;
        font-size: 12px;

        &::placeholder {
          font-size: 12px;
        }
      }

      &:focus {
        outline: none;
      }
    }

    /* For Input Type Number */

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Type Radio And Check Box */
    &[type="radio"],
    &[type="checkbox"] {
      height: 18px !important;
      width: 18px !important;
      accent-color: $second;
      margin: 0;
      cursor: pointer;
      position: relative;

      @include maxMediaQuery($lg) {
        height: 16px !important;
        width: 16px !important;
      }

      @include maxMediaQuery($md) {
        height: 14px !important;
        width: 14px !important;
      }

      &.error-radio::before {
        border: 1px solid red;
        border-radius: 50%;
      }

      &.error-checkbox::before {
        border: 1px solid red;
        border-radius: 2px;
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;

        @include maxMediaQuery($lg) {
          height: 16px !important;
          width: 16px !important;
        }

        @include maxMediaQuery($md) {
          height: 14px !important;
          width: 14px !important;
        }
      }
    }

    /* For Input Type File */
    &[type="file"] {
      padding: 0px;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;

      @include maxMediaQuery($md) {
        font-size: 14px;
      }

      @include maxMediaQuery($sm) {
        font-size: 10px;
      }

      &::file-selector-button {
        margin-right: 15px;
        border: none;
        background: $second;
        padding: 0px 10px 8px;
        color: $light;
        cursor: pointer;
        transition: background 0.2s ease-in-out;
        height: 47px;

        @include maxMediaQuery($md) {
          width: 100px;
          padding: 8px;
          font-size: 14px;
        }

        @include maxMediaQuery($sm) {
          width: 80px;
          padding: 8px;
          height: 37px;
          font-size: 12px;
        }
      }
    }

    /* For Input Type Color*/
    &[type="color"] {
      padding: 0;
    }

    /* For Input Type Range*/
    &[type="range"] {
      padding: 0;
      appearance: none;
      -webkit-appearance: none;
      height: 8px;
      // background: linear-gradient(to right,red 30%, blue 30%);
      border-radius: 5px;
      outline: none;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: $second;
        cursor: pointer;
      }

      &::-moz-range-thumb {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: $second;
        cursor: pointer;
      }
    }
  }

  & .rangeShower {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $light;
  }

  /* Input Select */
  &.inputSelect {
    & .selectMain {
      width: 100%;
      margin-top: 10px;
      display: block;

      @include maxMediaQuery($sm) {
        margin-top: 3px;
      }

      & .selectBox {
        & input {
          &:focus {
            outline: none;
          }
        }

        border: none;
        background: $second;
        padding: 7px 10px 7px 20px;

        & .showSelectValue {
          // & p {
          //   display: none;
          // }

          & input {
            display: block;
          }
        }

        @include maxMediaQuery($lg) {
          font-weight: 400;
          padding: 10px 12px 10px 20px;
          font-size: 16px;
        }

        @include maxMediaQuery($sm) {
          padding: 10px 10px 10px 20px;
          font-size: 12px;
        }
      }

      & ul {
        width: 100%;
        z-index: 10;
        max-height: 220px;
        overflow: auto;

        @include maxMediaQuery($lg) {
          max-height: 135px;
        }

        & li {
          font-size: 18px;
          padding: 5px 18px;

          &:first-child {
            display: block;
          }

          @include maxMediaQuery($lg) {
            font-size: 14px;
          }

          @include maxMediaQuery($sm) {
            font-size: 12px;
          }
        }
      }
    }
  }

  & .multiImage {
    max-height: 200px;
    overflow: auto;

    &>div[data-index="index"] {
      width: 100px;
      height: 100px;
      margin: 20px;
      margin-left: 0;
      position: relative;

      &>div[data-remove="remove"] {
        position: absolute;
        top: -6px;
        right: -6px;
        width: 20px;
        height: 20px;
        background: $second;
        color: $light;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
      }

      & img[data-class="showImage"] {
        margin-top: 0;
      }
    }
  }

  /* Image Input */
  & img[data-class="showImage"] {
    width: 100px;
    height: 100px;
    border: 3px solid $second;
    border-radius: 10px;
    margin-left: 0;
  }

  & img[data-image="file"] {
    margin-top: 20px;
  }

  /* Normal Select */
  & .selectMain {
    font-family: $font-second;
    position: relative;
    display: inline-block;
    width: 100%;

    & .selectBox {
      font-size: 14px;
      width: 100%;
      border-radius: 5px;
      padding: 10px;
      appearance: none;
      background-color: $light !important;
      border: none;
      text-align: start;
      min-height: 36px;
      margin-top: 5px;

      @include maxMediaQuery($sm) {
        min-height: 30px;
      }


      & .showSelectValue {
        & p {
          display: block;
        }

        & input {
          display: none;
        }
      }

      @include maxMediaQuery($md) {
        font-size: 12px;
        padding: 5px;
      }

      &::after {
        display: none;
      }

      &:focus {
        outline: none;
      }
    }

    & ul {
      // max-width: 380px;
      position: absolute;
      top: calc(100% - 25px);
      margin: 0px;
      background: $light;
      box-shadow: 0 0 12px rgba(15, 34, 58, 0.121);
      width: 100%;
      z-index: 1111;
      max-height: 186px;
      overflow: auto;

      &.topSide {
        bottom: 100%;
        top: auto !important;
      }

      &.bottomSide {
        top: 70%;
        bottom: auto;
      }

      & li {
        font-size: 16px;
        padding: 3px 8px;
        cursor: pointer;
        display: flex;
        align-items: center;

        // &:first-child {
        //   display: none;
        // }

        &:hover {
          background: $second;
          color: $light !important;
        }

        @include maxMediaQuery($md) {
          font-size: 14px;
        }
      }
    }
  }

  .multiSelectionDisplay {
    background: $second;
    padding: 4px 8px;
    display: inline-flex;
    align-items: center;
    margin: 5px 5px;
    font-size: 13px;
    border-radius: 50px;
    color: #fff !important;
    position: relative;

    // &::before{
    //   content: "\eb97";
    //   font-family: remixicon !important;
    //   position: absolute;
    //   top: 50%;
    //   right: 3px;
    //   transform: translateY(-50%);
    // }
  }

  /*Text Area*/
  & textarea {
    width: 100%;
    background: $light;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;

    @include maxMediaQuery($lg) {
      font-size: 14px;
    }

    @include maxMediaQuery($sm) {
      font-size: 12px;
    }

    &:focus {
      outline-color: $second;
      outline-width: 1px;
    }

    &::placeholder {
      color: $gray;
      font-size: 14px;
    }
  }

  /* Box Image Files */
  .imageBoxMain {
    display: flex;
    align-items: start;
    // margin-top: 5px;

    & .boxImage {
      display: flex;
      align-items: center;
      height: 100px;
      width: 100px;
      min-height: 100px;
      min-width: 100px;
      border: 3px solid $second;
      margin-right: 15px;
      margin-bottom: 20px;
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      color: $second;

      @include maxMediaQuery($lg) {
        height: 70px;
        width: 70px;
        min-height: 70px;
        min-width: 70px;
        margin-right: 15px;
        margin-bottom: 15px;
      }

      @include maxMediaQuery($sm) {
        height: 50px;
        width: 50px;
        min-height: 50px;
        min-width: 50px;
        margin-right: 10px;
        margin-bottom: 10px;
      }

      @include maxMediaQuery($smm) {
        margin-right: 5px;
        margin-bottom: 5px;
      }

      &::before {
        font-weight: 300;
        font-size: 50px;

        @include maxMediaQuery($md) {
          font-size: 40px;
        }

        @include maxMediaQuery($sm) {
          font-size: 30px;
        }

        height: 100%;
        width: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      & input {
        transform: scale(2);
        margin-top: 0;
        opacity: 0;
        position: absolute;
      }
    }

    & .multiImage {
      max-width: unset;
      overflow: unset;

      & div[data-index="index"] {
        margin-top: 0;

        @include maxMediaQuery($lg) {
          height: 70px;
          width: 70px;
          min-height: 70px;
          min-width: 70px;
          margin-right: 15px;
          margin-bottom: 15px;
        }

        @include maxMediaQuery($sm) {
          height: 50px;
          width: 50px;
          min-height: 50px;
          min-width: 50px;
          margin-right: 10px;
          margin-bottom: 10px;
        }

        @include maxMediaQuery($smm) {
          margin-right: 5px;
          margin-bottom: 5px;
        }

        & img[data-class="showImage"] {
          border: 3px solid $second;
          border-radius: 10px;

          @include maxMediaQuery($lg) {
            height: 70px;
            width: 70px;
            min-height: 70px;
            min-width: 70px;
            margin-right: 15px;
            margin-bottom: 15px;
          }

          @include maxMediaQuery($sm) {
            height: 50px;
            width: 50px;
            min-height: 50px;
            min-width: 50px;
            margin-right: 10px;
            margin-bottom: 10px;
          }

          @include maxMediaQuery($smm) {
            margin-right: 5px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  /* For Input Type Password , Search */

  & .passHideShow,
  .searching,
  .activeIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 14px;
    cursor: pointer;
  }

  /* Type Radio And Check Box Label */

  &.radio,
  &.checkbox {
    display: flex;
    align-items: center;

    & label {
      margin-bottom: 0;
      margin-right: 10px;
      cursor: pointer;
    }
  }
}

.installmentTable {
  input {
    background-color: transparent;
    border: solid 1px $gray;
    padding: 0 10px;
  }
}

input:read-only {
  outline: none;
}