@import "../../../assets/scss/abstracts/variabale";

/* ============= Table CSS ============= */
tbody,
td,
tfoot,
th,
thead,
tr {

    padding: 15px 10px;
    font-size: 14px;
    text-align: start;
    white-space: nowrap;
    color: $darkGray;
    font-weight: 500;

    @include maxMediaQuery($md) {
        font-size: 14px;
    }
}

thead tr {
    &:first-child {
        background-color: #fafaff;
    }

}

thead,
tr {
    font-size: 16px;
    box-shadow: 0 3px 5px #00000026;

    &:first-child {
        text-align: center;
        width: 70px;
    }

    &:not(:last-child) {
        border-bottom-width: 3px;
        border-color: $body;
    }
}

th {
    font-size: 16px;

    &:first-child {
        text-align: center;
        // width: 70px;
    }

    border-bottom-width: 3px;
    border-color: $body;
}

tbody {
    & td {
        &:first-child {
            text-align: center;
            // width: 70px;
        }
    }
}

thead {
    & th {
        &:first-child {
            &>div {
                justify-content: center;
            }
        }
    }
}


/* ======== Leave Table CSS ======== */

.leaveDetails {
    & tr {
        border: 0;

        & td {
            color: $second;
            padding: 12px 10px;
            width: 25%;

            &>* {
                color: $second;
            }
        }
    }
}

/* ======== Sub Table CSS ======== */

.subTeb {
    padding: 0;

    &>td {
        padding: 0;

        & .subTebContent {
            padding: 12px 10px;
        }
    }
}

.mainTable {
    // max-height: calc(100vh - 117px - 250px);
    max-height: 70vh;

    @media screen and (max-height:650px) {
        // max-height: calc(100vh - 117px - 0px);
        max-height: 75vh;
    }

    overflow: auto;
    // border: 1px solid lighten($darkGray, 40);
    border-radius: 10px;

    box-shadow: 0 3px 15px #00000026;

    & .primeTable {
        width: 100%;
        background-color: rgba($light, .5);

        & th,
        & thead {
            // background-color: $body;
            position: sticky;
            top: 0;
            z-index: 1;
            color: $gray;
            font-weight: 500;

            & .sortIcon {
                transition: .3s;

                &:hover {
                    color: $second;
                }
            }

        }
    }
}

