@import "abstracts/variabale";
@import "abstracts/global-variable";

body {
    background-color: $body;
}


.mainAdminGrid {


    /* ============= Navbar CSS ============= */

    & .mainNavbar {
        position: sticky;
        top: 0;
        transition-delay: 1s;
        z-index: 5;



        & .navBar {
            padding: 20px 30px;

            & .innerNavbar {
                background-color: $light;
                height: 100%;
                padding: 10px 30px;
                border-radius: 10px;
                // border-bottom: 1px solid lighten($third, 20);

                & .rightNav {
                    & .adminProfile {
                        & .adminPic {
                            height: 45px;
                            width: 45px;
                            border-radius: 5px;
                            overflow: hidden;
                        }

                        & .adminDetails {
                            & h6 {
                                font-size: 16px;
                            }

                            & p {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }

        @include maxMediaQuery($md) {
            & .navBar {
                // padding: 12px 0;

                & .innerNavbar {
                    padding: 19px 15px;

                    & .rightNav {
                        & .adminProfile {
                            & .adminPic {
                                height: 30px;
                                width: 30px;
                            }

                            & .adminDetails {
                                & h6 {
                                    font-size: 12px;
                                }

                                & p {
                                    font-size: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    & .mainAdmin {
        // height: 100vh;
        overflow-x: auto;



        /* ============= Admin CSS ============= */

        .adminStart {
            // max-height: calc(100vh - 117px);
            padding: 10px 30px 0 0;
            position: relative;

            @include maxMediaQuery($md) {
                padding: 20px 10px 0;
            }
        }
    }


    /* ============= Dashboard CSS ============= */
    .mainDashboard {

        & .dashBoardHead {
            @include maxMediaQuery($md) {
                & h2 {
                    font-size: 16px;
                }

                & p {
                    font-size: 14px;
                }
            }
        }

        & .mainDashbox {
            margin-top: 15px;

            & .dashBox {
                padding: 20px 15px;
                background-color: $body;
                box-shadow: 0 0 0 #00000000;
                border: 1px solid lighten($third, 20);
                transition: .3s;
                border-radius: 10px;

                &:hover {
                    border: solid 1px transparent;
                    box-shadow: 20px 20px 30px #00000014;
                }

                & .boxHead {
                    & p {
                        font-size: 16px;
                        font-weight: 500;
                        color: $darkGray;

                        @include maxMediaQuery($sm) {
                            font-size: 12px;
                        }
                    }
                }

                & .boxBody {

                    & h2 {
                        font-size: 26px;
                    }

                    @include maxMediaQuery($sm) {

                        & h2 {
                            font-size: 16px;
                        }
                    }
                }

                & .boxIcon {
                    min-width: 45px;
                    max-width: 45px;
                    min-height: 45px;
                    max-height: 45px;

                    @include maxMediaQuery($sm) {
                        min-width: 35px;
                        max-width: 35px;
                        min-height: 35px;
                        max-height: 35px;
                    }
                }
            }
        }

        & #chart {
            max-height: 500px !important;
            overflow: hidden;

            & .apexcharts-toolbar {
                z-index: 3 !important;
            }

            & .apexcharts-toolbar {

                & div[title="Selection Zoom"],
                & div[title="Panning"] {
                    display: none;
                }

                &>div {
                    margin: 0 5px;

                    & svg {
                        fill: $second;
                        height: 30px;
                        width: 30px;

                        @include maxMediaQuery($md) {
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }

        }
    }


    /* ============= DashBox CSS ============= */
    .dashBox {
        padding: 20px 15px;
        background-color: $body;
        box-shadow: 0 0 0 #00000000;
        border: 1px solid $gray2;
        transition: .3s;
        border-radius: 10px;

        &:hover {
            border: solid 1px transparent;
            box-shadow: 20px 20px 30px #00000014;
        }

        & .boxHead {
            & p {
                font-size: 16px;
                font-weight: 500;
                color: $darkGray;

                @include maxMediaQuery($sm) {
                    font-size: 12px;
                }
            }
        }

        & .boxBody {

            & h2 {
                font-size: 26px;
            }

            @include maxMediaQuery($sm) {

                & h2 {
                    font-size: 16px;
                }
            }
        }

    }

    // =============== Dashbox ===================

    .dashBoardBody {
        margin-top: 40px;

        & .employeeSystem {

            & .pieChart {
                fill: transparent;
                border: 1px solid $gray2;
                border-radius: 15px;
                overflow: hidden;
                // height: 320px !important;
                // & rect {
                //   fill: transparent;
                // }
            }

            & .lineChart {
                fill: transparent;
                border: 1px solid $gray2;
                border-radius: 15px;
                overflow: hidden;
            }
        }
    }

    & .employeeDash,
    & .hrSystem,
    & .reptionistSystem {
        &>div {
            margin-bottom: 15px;

            & .boxBody {
                margin-top: 35px !important;
            }
        }
    }

}




/* ============= Profile CSS ============= */

.mainProfileBoard {

    & .mainProfileBox {
        margin-top: 15px;

        & .profileBox {
            padding: 40px 15px;
            height: 360px;
            width: 600px;
            background-color: $body;
            box-shadow: 0 0 0 #00000000;
            border: 1px solid lighten($third, 20);
            transition: .3s;
            border-radius: 10px;

            & .profileImg {
                height: 150px;
                width: 30%;
                border-radius: 50%;
            }

            & .profileHeading {
                font-size: 30px;
                margin-top: 20px;
            }

            & .boxHead {
                & p {
                    font-size: 16px;
                    font-weight: 500;
                    color: $darkGray;

                    @include maxMediaQuery($sm) {
                        font-size: 12px;
                    }
                }
            }

            & .boxBody {

                & h2 {
                    font-size: 26px;
                }

                @include maxMediaQuery($sm) {

                    & h2 {
                        font-size: 16px;
                    }
                }
            }

            & .boxIcon {
                min-width: 45px;
                max-width: 45px;
                min-height: 45px;
                max-height: 45px;

                @include maxMediaQuery($sm) {
                    min-width: 35px;
                    max-width: 35px;
                    min-height: 35px;
                    max-height: 35px;
                }
            }
        }
    }

    & #chart {
        max-height: 500px !important;
        overflow: hidden;

        & .apexcharts-toolbar {
            z-index: 3 !important;
        }

        & .apexcharts-toolbar {

            & div[title="Selection Zoom"],
            & div[title="Panning"] {
                display: none;
            }

            &>div {
                margin: 0 5px;

                & svg {
                    fill: $second;
                    height: 30px;
                    width: 30px;

                    @include maxMediaQuery($md) {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }

    }
}




/* ============= Attendance CSS ============= */
.masterAttendanceTable {
    position: relative;

    & .mainAttendenceTable {
        height: calc(100vh - 165px);

        @media screen and (max-height:600px) {
            height: 100vh;

        }

        overflow: auto;
        box-shadow: inset -3px 0 10px #0000001c,
        inset 3px 0 10px #0000001c;

        & .thisMonth,
        & .thisYear {
            background-color: $body;
            box-shadow: 0 5px 10px #00000029;
            padding: 0 0 10px;
            position: sticky;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 4;
            margin-bottom: 20px;
            overflow: auto;

            & table {
                & tr {
                    box-shadow: unset;

                    & th {
                        border-width: 1px;
                        border-color: #d8d8d8;
                        cursor: pointer;
                        transition: .2s;
                        padding: 10px 16px;
                        font-size: 14px;

                        @include maxMediaQuery($md) {
                            padding: 5px 10px;
                            font-size: 12px;
                        }

                        @media screen and (max-height:600px) {
                            padding: 5px 10px;
                            font-size: 12px;
                        }

                        &:hover,
                        &.activeMonth {
                            background-color: $darkGray;
                            color: $light;
                        }
                    }
                }
            }
        }

        & .thisYear {
            position: absolute;
            top: auto;
            bottom: 0;
            left: 0;
            padding: 10px 0 0;
            box-shadow: 0 -10px 10px #00000029;
            width: 100%;
            z-index: 4;
            margin-bottom: 0;
        }

        & .batchWiseAttendenceTable {
            padding: 0 10px;
            margin-bottom: 30px;

            &:nth-last-child(2) {
                margin-bottom: 75px;
            }

            & .batchTitle {
                width: 100%;
                text-align: center;

                .headTitle text-second text-uppercase {
                    // background-color: darken($darkGray, 15);
                    padding: 10px 30px;
                    color: $light;
                    color: darken($darkGray, 15);
                    display: inline-block;
                    margin: 0px auto 15px;
                    // border-radius: 10px;
                    box-shadow: 3px 3px 10px #0000003c;

                    @include maxMediaQuery($md) {
                        padding: 7px 15px;
                        font-size: 12px;
                    }

                    @media screen and (max-height:600px) {
                        padding: 7px 15px;
                        font-size: 12px;
                    }
                }
            }

            & .attendanceTable {
                overflow: auto;
                position: relative;
                max-height: 460px;
                // border-radius: 10px;

                & table {
                    max-width: 100%;
                    font-size: 20px;

                    & tr {
                        position: relative;
                        border-width: 0;

                        & td,
                        & th {
                            text-align: center;
                            font-size: 14px;
                            border-bottom: solid 1px #8a8a8a;
                            border-left: solid 1px #8a8a8a;
                            min-width: 43px;

                            @include maxMediaQuery($md) {
                                min-width: 35px;
                            }

                            @media screen and (max-height:600px) {
                                min-width: 35px;
                            }

                            text-transform: capitalize;

                            &:first-child {
                                position: sticky;
                                left: 0;
                                top: 0;
                                background-color: lighten($darkGray, 40);
                                min-width: 40px;
                                max-width: 40px;
                                border-left: 0;
                            }

                            &:nth-child(2) {
                                position: sticky;
                                top: 0;
                                left: 40px;
                                background-color: lighten($darkGray, 40);
                                text-align: start;
                                border-left: 0;
                            }

                            &:last-child {
                                position: sticky;
                                top: 0;
                                right: 0;
                                background-color: lighten($darkGray, 40);
                                min-width: 55px;
                                border-left: 0;

                                @include maxMediaQuery($sm) {
                                    position: unset;
                                }
                            }

                            &:nth-last-child(2) {
                                position: sticky;
                                top: 0;
                                right: 55px;
                                background-color: lighten($darkGray, 40);
                                min-width: 38px;
                                border-left: 0;

                                @include maxMediaQuery($sm) {
                                    position: unset;
                                }
                            }

                            &:nth-last-child(3) {
                                position: sticky;
                                top: 0;
                                right: 93px;
                                background-color: lighten($darkGray, 40);
                                min-width: 38px;
                                border-left: 0;

                                @include maxMediaQuery($sm) {
                                    position: unset;
                                }
                            }

                            &:nth-last-child(4) {
                                position: sticky;
                                top: 0;
                                right: 131px;
                                background-color: lighten($darkGray, 40);
                                min-width: 38px;
                                border-left: 0;

                                @include maxMediaQuery($sm) {
                                    position: unset;
                                }
                            }

                            @include maxMediaQuery($md) {
                                font-size: 12px;
                                padding: 7px 10px;
                            }

                            @media screen and (max-height:600px) {
                                font-size: 12px;
                                padding: 7px 10px;
                            }
                        }

                        & th {
                            position: sticky;
                            top: 0;
                            left: 0;
                            z-index: 1;
                            background-color: darken($darkGray, 15);
                            color: #fff;

                            &:first-child,
                            &:nth-child(2),
                            &:last-child,
                            &:nth-last-child(2),
                            &:nth-last-child(3),
                            &:nth-last-child(4) {
                                z-index: 2;
                                background-color: $darkGray;
                                box-shadow: 0 4px 4px #000;
                            }
                        }

                        & .attendanceEditer {
                            padding: 0;

                            & input {
                                max-height: 100%;
                                width: 100%;
                                padding: 12px 8px;
                                border: 0;
                                outline-color: darken($darkGray, 15);
                                text-align: center;
                                cursor: pointer;
                                caret-color: transparent;

                                @include maxMediaQuery($md) {
                                    padding: 7px 5px;
                                }

                                @media screen and (max-height:600px) {
                                    padding: 7px 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

/* ============= Installment CSS ============= */
.installmentTable {
    min-height: 300px;
    max-height: 600px;
    overflow: auto;
}

// ======= Show Attendance Table ==========
.attendanceShowTable {
    min-height: 300px;
    max-height: 600px;
    overflow: auto;

    table {
        border-collapse: unset;

        & tr {
            position: sticky;
            top: 2px !important;
            box-shadow: unset;


            & td,
            & th {
                text-align: center;
                font-size: 14px;
                min-width: 43px;
                border: none;
                padding: 0;

                & .tblDiv {
                    border: solid 1px;
                    padding: 5px;
                }



                text-transform: capitalize;


                &.thHead,
                &.tdBody {
                    width: 100%;
                    height: 100%;
                    position: sticky;
                    top: 0;
                    background-color: lighten($darkGray, 40);
                    z-index: 1;
                    cursor: pointer;


                    &.yearHead,
                    &.yearBody {
                        left: 2px;
                        min-width: 61px;
                        max-width: 61px;
                    }

                    &.monthHead,
                    &.monthBody {
                        left: 65px;
                        min-width: 102px;
                        max-width: 102px;
                    }

                    &.totalHead,
                    &.totalBody {
                        right: 2px;
                    }

                    &.hHead,
                    &.hBody {
                        right: 52px;
                    }

                    &.aHead,
                    &.aBody {
                        right: 97px;
                    }

                    &.lHead,
                    &.lBody {
                        right: 142px;
                    }

                    &.pHead,
                    &.pBody {
                        right: 187px;
                    }

                    &.dataHead,
                    &.dataBody {
                        right: 187px;
                        left: 65px;
                        z-index: 0;
                    }

                    &.dataBody {
                        background-color: $light;
                    }

                    &.thHead {
                        background-color: $darkGray;
                        color: $light;

                        & .tblDiv {
                            border-color: $darkGray;
                        }
                    }

                    &.dataHead {
                        background-color: darken($darkGray, 15);
                    }


                    @media screen and (max-height:600px),
                    screen and (max-width :$md) {
                        min-width: 30px;
                        min-height: 30px;
                        width: 30px;
                        height: 30px;
                        font-size: 10px;

                        &.yearBody {
                            height: 100% !important;

                            & .tblDiv {
                                font-size: 14px;
                            }
                        }

                        &.monthHead,
                        &.monthBody {
                            left: 50px;
                        }

                        &.hHead,
                        &.hBody {
                            right: 42px;
                        }

                        &.aHead,
                        &.aBody {
                            right: 74px;
                        }

                        &.lHead,
                        &.lBody {
                            right: 106px;
                        }

                        &.pHead,
                        &.pBody {
                            right: 138px;
                        }

                        &.dataHead,
                        &.dataBody {
                            right: 138px;
                        }
                    }

                }
            }


        }
    }

    & .endYear {
        & td {
            border-bottom: solid 3px #9b9b9b !important;
        }
    }

    & .yearBody {
        padding: 0;
        margin: 0;
        border: solid 1px;
        background-color: $light;

        & .tblDiv {
            padding: 5px;
            border: 0;
            transform: rotate(270deg);
            font-size: 20px;
        }
    }

    .showAtten {
        width: 40px;
        min-height: 40px;
        max-height: 40px;
    }
}

/* ============= Staff Info CSS ============= */

.staffInfo {
    margin-top: 20px;

    & .row {
        &>div {

            & .staffDetails {
                font-size: 16px;
                display: flex;

                &:not(.staffTotal) {
                    justify-content: center;
                }

                &.staffTotal {
                    padding-bottom: 10px;
                }

                & .textTitle {
                    color: $darkGray;
                }

                & .textDes {
                    color: $second;
                    font-weight: 500;
                    margin-left: 5px;
                }
            }
        }
    }

    & .staffInfoDetails {
        border: 1px solid $second;
        border-radius: 20px;
        margin-bottom: 20px;

        & .staffInfoProfileTitle {
            font-family: $font-second;
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        & .staffInfoDetailsBox {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 5px;

            & .staffInfoDetailTitle {
                font-family: $font-second;
                font-size: 14px;

                @include maxMediaQuery($md) {
                    font-size: 12px;
                }
            }

            & .inputData {

                & input,
                & textarea {
                    margin: 0;
                    font-size: 14px;
                    text-align: end;
                    height: auto;
                    width: 100%;
                    padding: 0 5px;
                    font-weight: 600;
                    border-color: transparent;
                    white-space: pre-wrap;

                    @include maxMediaQuery($md) {
                        font-size: 12px;
                    }

                    &[type="date"]::-webkit-calendar-picker-indicator {
                        display: none;
                    }

                    &[type="time"]::-webkit-calendar-picker-indicator {
                        display: none;
                    }
                }

                & textarea {
                    min-height: 50px;
                    resize: vertical;
                    overflow: auto;
                    word-wrap: break-word;
                }

                & .errorMessage {
                    display: none;
                }
            }
        }
    }
}

/* ============= Month Table CSS ============= */

.monthTable {
    & tr {
        td {
            background-color: $second-light;
            color: $second;
            padding: 5px 7px;
            margin: 0 3px;
            display: inline-block;
            font-size: 12px;
            border-radius: 5px;
            border: 1px solid $second;
            cursor: pointer;

            &.active {
                background-color: $second;
                color: $light;
            }

        }
    }
}

.ws-normal {
    white-space: normal;
}

.mainLearn {
    cursor: pointer;

    i {
        transition: .3s;

        &:hover {
            color: $second;
        }
    }
    ul,ol{
        margin-left: 20px;
    }
    li{
        list-style: unset;
    }
}


// =========== Accordion ============

.accordionBox {
    box-shadow: 0 0 37px -4px rgba(0, 0, 0, 0.2);

    .masterBody {
        border-top: solid #D6D6D6 1px;
    }

    .accordionBody {
        ul {
            margin-left: 20px;

            li {
                list-style: disc;
                line-height: 25px;
            }
        }
    }
}