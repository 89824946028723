@import "reboot";
@import "variabale";
@import "global-variable";
@import url('https://cdnjs.cloudflare.com/ajax/libs/remixicon/4.5.0/remixicon.min.css');

dl,
ol,
ul,
p {
    margin-top: 0;
    margin-bottom: 0
}

ol,
ul {
    padding-left: 0
}

$enable-container-classes: true;

@mixin media-breakpoint-up($breakpoint, $breakpoints) {
    @if map-has-key($breakpoints, $breakpoint) {
        $width: map-get($breakpoints, $breakpoint);

        @media (min-width: #{$width}) {
            @content
        }
    }
}

@function breakpoint-infix($name, $breakpoints) {
    @if map-has-key($breakpoints, $name) {
        @return "-#{$name}"
    }

    @return ""
}

@mixin make-container() {
    width: 100%;
    padding-right: calc(var(--bs-gutter-x, 1.5rem) * .5);
    padding-left: calc(var(--bs-gutter-x, 1.5rem) * .5);
    margin-right: auto;
    margin-left: auto
}

@if $enable-container-classes {

    .container,
    .container-fluid {
        @include make-container()
    }

    @each $breakpoint, $container-max-width in $container-max-widths {
        .container-#{$breakpoint} {
            @extend .container-fluid
        }

        @include media-breakpoint-up($breakpoint, $responsive) {
            %responsive-container-#{$breakpoint} {
                max-width: $container-max-width
            }

            $extend-breakpoint: true;

            @each $name, $width in $responsive {
                @if ($extend-breakpoint) {
                    $infix: breakpoint-infix($name, $responsive);

                    .container,
                    .container#{$infix} {
                        @extend %responsive-container-#{$breakpoint}
                    }

                    @if ($breakpoint ==$name) {
                        $extend-breakpoint: false
                    }
                }
            }
        }
    }
}

@each $key, $value in $themeAll {
    .text-#{$key} {
        color: #{$value} !important
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @each $key, $value in $themeAll {
            .text-#{$size}-#{$key} {
                color: #{$value} !important
            }
        }
    }
}

@each $key, $value in $themeAll {
    .bg-#{$key} {
        background: #{$value} !important
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @each $key, $value in $themeAll {
            .bg-#{$size}-#{$key} {
                background: #{$value} !important
            }
        }
    }
}

@each $alignKey, $alignValue in $side {
    .text-#{$alignKey} {
        text-align: $alignValue !important
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @each $alignKey, $alignValue in $side {
            .text-#{$size}-#{$alignKey} {
                text-align: $alignValue !important
            }
        }
    }
}

@each $wrapKey, $wrapValue in $wrap {
    .text-#{$wrapKey} {
        text-wrap: $wrapValue !important
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @each $wrapKey, $wrapValue in $wrap {
            .text-#{$size}-#{$wrapKey} {
                text-wrap: $wrapValue !important
            }
        }
    }
}

@mixin overflowText($line) {
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis
}

@for $i from 1 through $textOverFlowCount {
    .text-overflow-#{$i} {
        @include overflowText($i)
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @for $i from 1 through $textOverFlowCount {
            .text-overflow-#{$size}-#{$i} {
                @include overflowText($i)
            }
        }
    }
}

@each $textTransKey, $textTransValues in $textTransform {
    .text-#{$textTransKey} {
        text-transform: $textTransValues !important
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @each $textTransKey, $textTransValues in $textTransform {
            .text-#{$size}-#{$textTransKey} {
                text-transform: $textTransValues !important
            }
        }
    }
}

@each $textDecorLineKey, $textDecorLineValues in $textDecorLine {
    .text-decoration-#{$textDecorLineKey} {
        text-decoration-line: $textDecorLineValues !important
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @each $textDecorLineKey, $textDecorLineValues in $textDecorLine {
            .text-decoration-#{$size}-#{$textDecorLineKey} {
                text-decoration-line: $textDecorLineValues !important
            }
        }
    }
}

@each $textDecorStyleKey, $textDecorStyleValues in $textDecorStyle {
    .text-decoration-#{$textDecorStyleKey} {
        text-decoration-style: $textDecorStyleValues !important
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @each $textDecorStyleKey, $textDecorStyleValues in $textDecorStyle {
            .text-decoration-#{$size}-#{$textDecorStyleKey} {
                text-decoration-style: $textDecorStyleValues !important
            }
        }
    }
}

@each $key, $value in $themeAll {
    .text-decoration-#{$key} {
        text-decoration-color: #{$value} !important
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @each $key, $value in $themeAll {
            .text-decoration-#{$size}-#{$key} {
                text-decoration-color: #{$value} !important
            }
        }
    }
}

@for $i from 1 through $fontSizeCount {
    .fs-#{$i} {
        font-size: #{$i}px !important
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @for $i from 1 through $fontSizeCount {
            .fs-#{$size}-#{$i} {
                font-size: #{$i}px !important
            }
        }
    }
}

@for $i from 1 through $lineHeightCount {
    .lh-#{$i} {
        line-height: #{$i}px !important
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @for $i from 1 through $lineHeightCount {
            .lh-#{$size}-#{$i} {
                line-height: #{$i}px !important
            }
        }
    }
}

@for $i from 1 through $fontSizeAndLineHeightCount {
    .fsl-#{$i} {
        font-size: #{$i}px !important;
        line-height: #{$i}px !important
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @for $i from 1 through $fontSizeAndLineHeightCount {
            .fsl-#{$size}-#{$i} {
                font-size: #{$i}px !important;
                line-height: #{$i}px !important
            }
        }
    }
}

@for $i from 1 through 9 {
    .fw-#{$i * 100} {
        font-weight: $i * 100 !important
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @for $i from 1 through 9 {
            .fw-#{$size}-#{$i} {
                font-weight: $i * 100 !important
            }
        }
    }
}

@each $key in $borderStyle {
    .border-#{$key} {
        border-style: $key !important
    }
}

@each $key in $borderStyle {
    @each $angleKey in $angles {
        .border-#{$angleKey}-#{$key} {
            border-#{$angleKey}-style: $key !important
        }
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @each $key in $borderStyle {
            .border-#{$size}-#{$key} {
                border-style: $key !important
            }
        }
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @each $key in $borderStyle {
            @each $angleKey in $angles {
                .border-#{$size}-#{$angleKey}-#{$key} {
                    border-#{$angleKey}-style: $key !important
                }
            }
        }
    }
}

@each $key, $value in $themeAll {
    .border-#{$key} {
        border-color: #{$value} !important
    }
}

@each $key, $value in $themeAll {
    @each $angleKey in $angles {
        .border-#{$angleKey}-#{$key} {
            border-#{$angleKey}-color: #{$value} !important
        }
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @each $key, $value in $themeAll {
            .border-#{$size}-#{$key} {
                border-color: #{$value} !important
            }
        }
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @each $key, $value in $themeAll {
            @each $angleKey in $angles {
                .border-#{$size}-#{$angleKey}-#{$key} {
                    border-#{$angleKey}-color: #{$value} !important
                }
            }
        }
    }
}

@for $i from 0 through $borderWidthCount {
    .border-#{$i} {
        border-width: #{$i}px !important
    }
}

@for $i from 0 through $borderWidthCount {
    @each $angleKey in $angles {
        .border-#{$angleKey}-#{$i} {
            border-#{$angleKey}-width: #{$i}px !important
        }
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @for $i from 0 through $borderWidthCount {
            .border-#{$size}-#{$i} {
                border-width: #{$i}px !important
            }
        }
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @for $i from 0 through $borderWidthCount {
            @each $angleKey in $angles {
                .border-#{$size}-#{$angleKey}-#{$i} {
                    border-#{$angleKey}-width: #{$i}px !important
                }
            }
        }
    }
}

@each $styleKey in $borderStyle {
    @each $key, $value in $themeAll {
        @for $i from 0 through $wholeBorderWidthCount {
            .border-#{$styleKey}-#{$key}-#{$i} {
                border: #{$styleKey}$value #{$i}px !important
            }
        }
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @each $styleKey in $borderStyle {
            @each $key, $value in $themeAll {
                @for $i from 0 through $wholeBorderWidthCount {
                    .border-#{$styleKey}-#{$key}-#{$i}-#{$size} {
                        border: #{$styleKey}$value #{$i}px !important
                    }
                }
            }
        }
    }
}

@each $styleKey in $borderStyle {
    @each $key, $value in $themeAll {
        @for $i from 0 through $wholeBorderWidthCount {
            @each $angleKey in $angles {
                .border-#{$angleKey}-#{$styleKey}-#{$key}-#{$i} {
                    border-#{$angleKey}: #{$styleKey}$value #{$i}px !important
                }
            }
        }
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @each $styleKey in $borderStyle {
            @each $key, $value in $themeAll {
                @for $i from 0 through $wholeBorderWidthCount {
                    @each $angleKey in $angles {
                        .border-#{$angleKey}-#{$styleKey}-#{$key}-#{$i}-#{$size} {
                            border-#{$angleKey}: #{$styleKey}$value #{$i}px !important
                        }
                    }
                }
            }
        }
    }
}

@mixin RadiusMixin($recio, $type, $radiusClass) {
    @for $i from 1 through $recio {
        .border-#{$radiusClass}-#{$i} {
            border-radius: #{$i}#{$type} !important
        }
    }

    @each $size, $value in $responsive {
        @media (min-width: #{$value}) {
            @for $i from 1 through $recio {
                .border-#{$radiusClass}-#{$size}-#{$i} {
                    border-radius: #{$i}#{$type} !important
                }
            }
        }
    }
}

@include RadiusMixin(100, px, radius);
@include RadiusMixin(100, "%", round);

@each $key in $borderStyle {
    .outline-#{$key} {
        outline-style: #{$key} !important
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @each $key in $borderStyle {
            .outline-#{$size}-#{$key} {
                outline-style: #{$key} !important
            }
        }
    }
}

@each $key, $value in $themeAll {
    .outline-#{$key} {
        outline-color: #{$value} !important
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @each $key, $value in $themeAll {
            .outline-#{$size}-#{$key} {
                outline-color: #{$value} !important
            }
        }
    }
}

@for $i from 1 through $outlineWidthCount {
    .outline-#{$i} {
        outline-width: #{$i}px !important
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @for $i from 1 through $outlineWidthCount {
            .outline-#{$size}-#{$i} {
                outline-width: #{$i}px !important
            }
        }
    }
}

@for $i from 1 through $outlineOffsetCount {
    .outline-offset-#{$i} {
        outline-offset: #{$i}px !important
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @for $i from 1 through $outlineOffsetCount {
            .outline-offset-#{$size}-#{$i} {
                outline-offset: #{$i}px !important
            }
        }
    }
}

@mixin WidthMixin($recio, $type, $widthClass) {
    @for $i from 1 through $recio {
        .#{$widthClass}-#{$i} {
            width: #{$i}#{$type} !important
        }

        .min-#{$widthClass}-#{$i} {
            min-width: #{$i}#{$type} !important
        }

        .max-#{$widthClass}-#{$i} {
            max-width: #{$i}#{$type} !important
        }

        .#{$widthClass}-mn-#{$i} {
            width: #{$i}#{$type} !important;
            max-width: #{$i}#{$type} !important;
            min-width: #{$i}#{$type} !important
        }
    }

    @each $size, $value in $responsive {
        @media (min-width: #{$value}) {
            @for $i from 1 through $recio {
                .#{$widthClass}-#{$size}-#{$i} {
                    width: #{$i}#{$type} !important
                }

                .min-#{$widthClass}-#{$size}-#{$i} {
                    min-width: #{$i}#{$type} !important
                }

                .max-#{$widthClass}-#{$size}-#{$i} {
                    max-width: #{$i}#{$type} !important
                }

                .#{$widthClass}-mn-#{$size}-#{$i} {
                    width: #{$i}#{$type} !important;
                    max-width: #{$i}#{$type} !important;
                    min-width: #{$i}#{$type} !important
                }
            }
        }
    }
}

@include WidthMixin($allWidths, px, width);
@include WidthMixin(100, "%", w);

@mixin HeightMixin($racio, $type, $heightClass) {
    @for $i from 1 through $racio {
        .#{$heightClass}-#{$i} {
            height: #{$i}#{$type} !important
        }
    }

    @each $size, $value in $responsive {
        @media (min-width: #{$value}) {
            @for $i from 1 through $racio {
                .#{$heightClass}-#{$size}-#{$i} {
                    height: #{$i}#{$type} !important
                }
            }
        }
    }
}

@include HeightMixin($allHeight, px, height);
@include HeightMixin(100, "%", h);
@include HeightMixin(100, vh, hvh);

@for $i from 1 through $heightAndWidth {
    .hw-#{$i} {
        height: #{$i}px !important;
        width: #{$i}px !important;
        max-height: #{$i}px !important;
        max-width: #{$i}px !important;
        min-height: #{$i}px !important;
        min-width: #{$i}px !important
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @for $i from 1 through $heightAndWidth {
            .hw-#{$size}-#{$i} {
                height: #{$i}px !important;
                width: #{$i}px !important;
                max-height: #{$i}px !important;
                max-width: #{$i}px !important;
                min-height: #{$i}px !important;
                min-width: #{$i}px !important
            }
        }
    }
}

@mixin margin-padding($length, $class, $count) {
    @if $class ==m {
        .m-auto {
            margin: auto !important
        }

        .m-auto-right {
            margin-right: auto !important
        }

        .m-auto-left {
            margin-left: auto !important
        }
    }

    @for $i from 0 through $count {
        .#{$class}-#{$i} {
            #{$length}: #{$i}px !important
        }

        @each $angle in $angles {
            .#{$class}-#{$i}-#{$angle} {
                #{$length}-#{$angle}: #{$i}px !important
            }
        }

        .#{$class}-#{$i}-y {
            #{$length}-top: #{$i}px !important;
            #{$length}-bottom: #{$i}px !important
        }

        .#{$class}-#{$i}-x {
            #{$length}-left: #{$i}px !important;
            #{$length}-right: #{$i}px !important
        }
    }

    @each $size, $value in $responsive {
        @media (min-width: #{$value}) {
            @if $class ==m {
                .m-#{$size}-auto {
                    margin: auto !important
                }

                .m-#{$size}-auto-right {
                    margin-right: auto !important
                }

                .m-#{$size}-auto-left {
                    margin-left: auto !important
                }
            }

            @for $i from 0 through $count {
                .#{$class}-#{$size}-#{$i} {
                    #{$length}: #{$i}px !important
                }

                @each $angle in $angles {
                    .#{$class}-#{$size}-#{$i}-#{$angle} {
                        #{$length}-#{$angle}: #{$i}px !important
                    }
                }

                .#{$class}-#{$size}-#{$i}-y {
                    #{$length}-top: #{$i}px !important;
                    #{$length}-bottom: #{$i}px !important
                }

                .#{$class}-#{$size}-#{$i}-x {
                    #{$length}-left: #{$i}px !important;
                    #{$length}-right: #{$i}px !important
                }
            }
        }
    }
}

@include margin-padding(margin, m, $marginCount);
@include margin-padding(padding, p, $paddingCount);

@each $displayKey, $displayValue in $display {
    .d-#{$displayKey} {
        display: $displayValue !important
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @each $displayKey, $displayValue in $display {
            .d-#{$size}-#{$displayKey} {
                display: $displayValue !important
            }
        }
    }
}

@each $flexKey, $flexValue in $flexDirection {
    .flex-#{$flexKey} {
        flex-direction: $flexValue !important
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @each $flexKey, $flexValue in $flexDirection {
            .flex-#{$size}-#{$flexKey} {
                flex-direction: $flexValue !important
            }
        }
    }
}

@each $flexKey, $flexValue in $wrap {
    .flex-#{$flexKey} {
        flex-wrap: $flexValue !important
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @each $flexKey, $flexValue in $wrap {
            .flex-#{$size}-#{$flexKey} {
                flex-wrap: $flexValue !important
            }
        }
    }
}

@mixin AlignJustifyMixin($name) {
    @each $sideKey, $sideValue in $side {
        .#{$name}-items-#{$sideKey} {
            #{$name}-items: $sideValue !important
        }
    }

    @each $size, $value in $responsive {
        @media (min-width: #{$value}) {
            @each $sideKey, $sideValue in $side {
                .#{$name}-items-#{$size}-#{$sideKey}-#{$size} {
                    #{$name}-items: $sideValue !important
                }
            }
        }
    }

    @each $sideKey, $sideValue in $allSide {
        .#{$name}-content-#{$sideKey} {
            #{$name}-content: $sideValue !important
        }
    }

    @each $size, $value in $responsive {
        @media (min-width: #{$value}) {
            @each $sideKey, $sideValue in $allSide {
                .#{$name}-content-#{$size}-#{$sideKey} {
                    #{$name}-content: $sideValue !important
                }
            }
        }
    }

    @each $sideKey, $sideValue in $side {
        .#{$name}-self-#{$sideKey} {
            #{$name}-self: $sideValue !important
        }
    }

    @each $size, $value in $responsive {
        @media (min-width: #{$value}) {
            @each $sideKey, $sideValue in $side {
                .#{$name}-self-#{$size}-#{$sideKey} {
                    #{$name}-self: $sideValue !important
                }
            }
        }
    }
}

@include AlignJustifyMixin(align);
@include AlignJustifyMixin(justify);

@each $positionKey in $positions {
    .position-#{$positionKey} {
        position: $positionKey !important
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @each $positionKey in $positions {
            .position-#{$size}-#{$positionKey} {
                position: $positionKey !important
            }
        }
    }
}

@mixin PositionAngle($recioStart, $recioEnd, $type, $values) {
    @each $positionAnglesKey in $angles {
        @for $i from $recioStart through $recioEnd {
            .#{$positionAnglesKey}-#{$i}#{$values} {
                #{$positionAnglesKey}: #{$i}#{$type} !important
            }
        }
    }

    @each $size, $value in $responsive {
        @media (min-width: #{$value}) {
            @each $positionAnglesKey in $angles {
                @for $i from $recioStart through $recioEnd {
                    .#{$positionAnglesKey}-#{$size}-#{$i}#{$values} {
                        #{$positionAnglesKey}: #{$i}#{$type} !important
                    }
                }
            }
        }
    }
}

@include PositionAngle($positionStartAngleCounts, $positionEndAngleCounts, px, "");
@include PositionAngle(-100, 100, "%", p);

@for $i from 1 through $zIndexCount {
    .z-#{$i} {
        z-index: $i !important
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @for $i from 1 through $zIndexCount {
            .z-#{$size}-#{$i} {
                z-index: $i !important
            }
        }
    }
}

.row {
    --mk-gutter-x: 1.5rem;
    --mk-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--mk-gutter-y));
    margin-right: calc(-.5 * var(--mk-gutter-x));
    margin-left: calc(-.5 * var(--mk-gutter-x));

    &>* {
        flex-shrink: 0;
        width: 100%;
        max-width: 100%;
        padding-right: calc(var(--mk-gutter-x) * .5);
        padding-left: calc(var(--mk-gutter-x) * .5);
        margin-top: var(--mk-gutter-y)
    }
}

@for $i from 12 through 1 {
    $total : calc(100 / 12);

    .col-#{$i} {
        flex: 0 0 auto;
        width: #{$total* $i}#{"%"};
        margin-bottom: 5px
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @for $i from 12 through 1 {
            $total : calc(100 / 12);

            .col-#{$size}-#{$i} {
                flex: 0 0 auto;
                width: #{$total* $i}#{"%"};
                margin-bottom: 5px
            }
        }
    }
}

@for $i from 0 through 100 {
    .opacity-#{$i} {
        opacity: $i / 100 !important
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @for $i from 1 through 100 {
            .opacity-#{$size}-#{$i} {
                opacity: $i / 100 !important
            }
        }
    }
}

@each $visibilityKey, $visibilityValue in $visibility {
    .visibility-#{$visibilityKey} {
        visibility: $visibilityValue !important
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @each $visibilityKey, $visibilityValue in $visibility {
            .visibility-#{$size}-#{$visibilityKey} {
                visibility: $visibilityValue !important
            }
        }
    }
}

@each $overflowKey, $overflowValue in $overflow {
    .overflow-#{$overflowKey} {
        overflow: $overflowValue !important
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @each $overflowKey, $overflowValue in $overflow {
            .overflow-#{$size}-#{$overflowKey} {
                overflow: $overflowValue !important
            }
        }
    }
}

@each $cursorKey, $cursorValue in $cursor {
    .cursor-#{$cursorKey} {
        cursor: $cursorValue !important
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @each $cursorKey, $cursorValue in $cursor {
            .cursor-#{$size}-#{$cursorKey} {
                cursor: $cursorValue !important
            }
        }
    }
}

@for $i from 1 through $transitionCounts {
    .trans-#{$i} {
        transition: #{"."}#{$i}s !important
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @for $i from 1 through $transitionCounts {
            .trans-#{$size}-#{$i} {
                transition: #{"."}#{$i}s !important
            }
        }
    }
}

@for $i from 1 through $orderCounts {
    .order-#{$i} {
        order: $i !important
    }
}

@each $size, $value in $responsive {
    @media (min-width: #{$value}) {
        @for $i from 1 through $orderCounts {
            .order-#{$size}-#{$i} {
                order: $i !important
            }
        }
    }
}