@import "../../../assets/scss/abstracts/variabale";


/* ============= Button CSS ============= */

button.themeButton {
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;

    @include maxMediaQuery($sm) {
        font-size: 12px;
        padding: 3px 10px;
        border-radius: 5px;
    }
}

button {
    cursor: pointer;
}

button:disabled {
    opacity: .5;
}

/* ============= Action Button CSS ============= */

button.actionButton {
    padding: 4px 10px;
    border-radius: 5px;
    font-size: 12px;

    @include maxMediaQuery($sm) {
        font-size: 12px;
        padding: 5px 10px;
        border-radius: 5px;
    }
}

button {
    cursor: pointer;
}

button:disabled {
    opacity: .5;
}


.mk-btn {
    background-color: $second;
    color: $light;
    border-color: transparent;
    padding: 10px 0;
}

/* ============= Toggle Button CSS ============= */

.switch {
    font-size: 17px;
    position: relative;
    display: inline-block;
    width: 3.5em;
    height: 2em;
    transform: scale(.8);


    /* Hide default HTML checkbox */
    & input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked+.slider {
            border: 2px solid $success;
        }

        &:checked+.slider:before {
            transform: translateX(1.5em);
            background-color: $success;
        }
    }

}

.slider {
    position: absolute;
    cursor: pointer;
    inset: 0;
    border: 2px solid darken($second-light, 10);
    border-radius: 50px;
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    box-shadow: -8px -4px 8px 0px #ffffff,
        8px 4px 12px 0px #d1d9e6,
        4px 4px 4px 0px #d1d9e6 inset,
        -4px -4px 4px 0px #ffffff inset;

    &::before {
        position: absolute;
        content: "";
        height: 1.4em;
        width: 1.4em;
        left: 0.2em;
        bottom: 0.2em;
        background-color: darken($second-light, 10);
        border-radius: inherit;
        transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
    }
}