$font-prime : 'Poppins',
sans-serif;
$font-second : 'Roboto',
sans-serif;

/* Custom Mixin */


@mixin midels($display, $jContent, $align) {
        display: $display;
        justify-content: $jContent;
        align-items: $align;
}

$prime-shadow : 0 0 10px rgba(0, 0, 0.1);

// 1. Container Props
// ============== Get Before Small Width ============
$xsm: 400px;
$smm: 450px;
$beforeWidth: (
        xsm:$xsm,
        smm:$smm,
);
$beforeContainer: (
        xsm: 370px,
        smm: 420px,
);

// 2. Color Props
// 3. Background Color Props

$prime: #dc2428;
$prime-light: #ffadaf;
$second: #a02829;
$second-light: #f59c9c;
$third: #bb9292;
$fourth: #F2F5FA;
$body: #F2EDF3;
$darkGray: #6D7080;
$gray: #b1b1b1;
$gray2: #d8d8d8;
$lightGray: #d8d8d8;


$customColor : (prime: $prime,
        prime-light: $prime-light,
        second: $second,
        second-light: $second-light,
        third: $third,
        fourth: $fourth,
        body: $body,
        darkGray: $darkGray,
        gray: $gray,
        gray2: $gray2,
);


// 4. Text Align Props // 
// 5. Text Wrap Props //
// 6. Text Overflow Props
$textOverFlowCount : 10;
// 7. Text Transform Props //
// 8. Text Decoration Props //
// 9. Font Size Props
$fontSizeCount : 200;
// 10. Line Height Props
$lineHeightCount : 200;
// 11. Font Size With Line Height Props
$fontSizeAndLineHeightCount : 200;
// 12. Font Weight Props //
// 13. Border Props
//      13.1. Border Style Props //
//      13.2. Border Color Props //
//      13.3. Border Width Props
$borderWidthCount : 10;
//      13.4. Whole Border Props
$wholeBorderWidthCount : 10;
// 14. Border Radius Props
//      14.1. Border Radius (PX) Props //
//      14.1. Border Radius (%) Props //
// 15. Outline Props
//      15.1. Outline Style Props //
//      15.2. Outline Color Props //
//      15.3. Outline Width Props
$outlineWidthCount : 10;
//      15.4. Outline Offset Props
$outlineOffsetCount : 10;
// 16. Width Props
//      16.1. Normal,Minimun,Maximum Width (PX) Props
$allWidths : 500;
//      16.2. Normal,Minimun,Maximum Width (%) Props
// 17. Height Props
//      17.1. Height (PX) Props
$allHeight : 500;
//      17.2. Height (%) Props
//      17.3. Height (vh) Props
// 18. Height & Width Props
$heightAndWidth : 500;
// 19. Margin Props
$marginCount : 200;
// 20. Padding Props
$paddingCount : 200;
// 21. Display Props //
// 22. Flex Props
//      22.1. Flex Direction Props //
//      22.2. Flex Wrap Props //
// 23. Align (Items,Content,Self) Props //
// 24. Justify (Items,Content,Self) Props //
// 25. Position Props //
// 26. Top, Bottom, Left, Right Props
//      26.1. Top, Bottom, Left, Right (PX)Props
$positionStartAngleCounts: -100;
$positionEndAngleCounts: 100;
//      26.2. Top, Bottom, Left, Right (%)Props
// 27. Z-Index Props
$zIndexCount : 10;
// 28. Row-Column Props //
// 29. Opacity Props //
// 30. Visibility Props //
// 31. Overflow Props //
// 32. Cursor Props //
// 33. Transition Props
$transitionCounts : 10;
// 34. Order Props
$orderCounts: 10;