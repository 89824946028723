@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import "global-variable";



/* Responsive Media Query*/
@mixin maxMediaQuery($breakpoint) {
  @media screen and (max-width: $breakpoint) {
    @content;
  }
}

@mixin minMediaQuery($breakpoint) {
  @media screen and (min-width: $breakpoint) {
    @content;
  }
}


$angles : (top,
  right,
  bottom,
  left,
);
$borderStyle : (solid,
  dotted,
  double,
  dashed,
  groove,
  ridge,
  inset,
  outset,
  none,
  hidden,
);



$side : (start:start,
  center:center,
  end:end,
);





// 1. Container Props

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;
$regulerWidth: (
  sm:$sm,
  md:$md,
  lg:$lg,
  xl:$xl,
  xxl:$xxl,
);
$responsive : map-merge($beforeWidth, $regulerWidth);

/* =========== Container Size */
$regulerContainer: (
  sm:576px,
  md:768px,
  lg:992px,
  xl:1200px,
  xxl:1400px,
);
$container-max-widths : map-merge($beforeContainer, $regulerContainer);


// 2. Color Props
// 3. Background Color Props

// ============== Colors ==============
$transparent: transparent;
$dark: #000000;
$light: #ffffff;

//success
$success: #198754;
$success-light: #D1E7DD;

//blue
$blue: #0D6EFD;
$blue-light: #CFE2FF;

//red
$danger: #DC3545;
$danger-light: #F8D7DA;

//yellow
$warning: #FFC107;
$warning-light: #FFF3CD;

//orange
$orange: #ff6207;
$orange-light: #fabd9a;

$fixedColor : (transparent: transparent,
  dark: #000000,
  light: #ffffff,

  //success
  success: #198754,
  success-light: #D1E7DD,

  //blue
  blue: #0D6EFD,
  blue-light: #CFE2FF,

  //red
  danger: #DC3545,
  danger-light: #F8D7DA,

  //yellow
  warning: #FFC107,
  warning-light: #FFF3CD,

  //orange
  orange: #ff6207,
  orange-light: #ffb68b,
);



$themeAll : map-merge($customColor, $fixedColor);


// 4. Text Align Props
$side : (start:start,
  center:center,
  end:end,
);

// 5. Text Wrap Props
$wrap : (wrap:wrap,
  nowrap:nowrap,
  wrap-reverse:wrap-reverse,
);

// 6. Text Overflow Props //

// 7. Text Transform Props
$textTransform: (
  capitalize:capitalize,
  uppercase:uppercase,
  lowercase:lowercase,
);

// 8. Text Decoration Props //
// 8.1. Text Decoration Line Props
$textDecorLine: (
  underline:underline,
  overline:overline,
  line-through:line-through,
  none:none,
);
// 8.2. Text Decoration Style Props
$textDecorStyle: (
  solid:solid,
  wavy:wavy,
  dotted:dotted,
  dashed:dashed,
  double:double,
);
// 8.3. Text Decoration Color Props

// 9. Font Size Props //

// 10. Line Height Props //

// 11. Font Size With Line Height Props //

// 12. Font Weight Props //

// 13. Border Props //
//      13.1. Border Style Props
//      13.2. Border Color Props
//      13.3. Border Width Props
//      13.4. Whole Border Props

// 14. Border Radius Props //
//      14.1. Border Radius (PX) Props
//      14.1. Border Radius (%) Props

// 15. Outline Props //
//      15.1. Outline Style Props
//      15.2. Outline Color Props
//      15.3. Outline Width Props
//      15.4. Outline Offset Props

// 16. Width Props //
//      16.1. Normal,Minimun,Maximum Width (PX) Props
//      16.2. Normal,Minimun,Maximum Width (%) Props

// 17. Height Props //
//      17.1. Height (PX) Props
//      17.2. Height (%) Props
//      17.3. Height (vh) Props

// 18. Height & Width Props //

// 19. Margin Props //

// 20. Padding Props //

// 21. Display Props
$display: (
  none:none,
  inline:inline,
  inline-block:inline-block,
  block:block,
  grid:grid,
  inline-grid:inline-grid,
  table:table,
  table-cell:table-cell,
  table-row:table-row,
  flex:flex,
  inline-flex:inline-flex,
);

// 22. Flex Props
//      22.1. Flex Direction Props
$flexDirection : (row:row,
  row-reverse:row-reverse,
  column:column,
  column-reverse:column-reverse,
);
//      22.2. Flex Wrap Props
// 23. Align (Items,Content,Self) Props
// 24. Justify (Items,Content,Self) Props
$allSide : (start:start,
  center:center,
  end:end,
  between:space-between,
  evenly:space-evenly,
  around:space-around,
);


// 25. Position Props
$positions : (relative,
  absolute,
  fixed,
  sticky,
  static,
);


// 26. Top, Bottom, Left, Right Props //
//      26.1. Top, Bottom, Left, Right (PX)Props //
//      26.2. Top, Bottom, Left, Right (%)Props //
// 27. Z-Index Props //
// 28. Row-Column Props //
// 29. Opacity Props //
// 30. Visibility Props
$visibility: (
  visible:visible,
  hidden:hidden,
  collapse:collapse,
);

// 31. Overflow Props
$overflow: (
  visible:visible,
  hidden:hidden,
  clip:clip,
  scroll:scroll,
  auto:auto,
);
// 32. Cursor Props

$cursor: (
  alias:alias,
  all-scroll:all-scroll,
  auto:auto,
  cell:cell,
  col-resize:col-resize,
  context-menu:context-menu,
  copy:copy,
  crosshair:crosshair,
  default:default,
  e-resize:e-resize,
  ew-resize:ew-resize,
  grab:grab,
  grabbing:grabbing,
  help:help,
  move:move,
  n-resize:n-resize,
  ne-resize:ne-resize,
  nesw-resize:nesw-resize,
  ns-resize:ns-resize,
  nw-resize:nw-resize,
  nwse-resize:nwse-resize,
  no-drop:no-drop,
  none:none,
  not-allowed:not-allowed,
  pointer:pointer,
  progress:progress,
  row-resize:row-resize,
  s-resize:s-resize,
  se-resize:se-resize,
  sw-resize:sw-resize,
  text:text,
  vertical-text:vertical-text,
  w-resize:w-resize,
  wait:wait,
  zoom-in:zoom-in,
  zoom-out:zoom-out,
);

// 33. Transition Props //
// 34. Order Props